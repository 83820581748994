import React from 'react'
import { graphql } from 'gatsby'
import tw, { css } from 'twin.macro'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import SeoPlugin from 'gatsby-plugin-wpgraphql-seo'
import ProgramHandbook from '../components/ProgramHandbook/ProgramHandbook'
import Seo from '../components/Seo'
import Wysiwyg from '../components/Wysiwyg'

const Handbook = ({ data }) => {
  const imageObject = getImage(data.handbook.featuredImage?.node.gatsbyImage)
  return (
    <>
      {/* <Seo title={data?.handbook.seo?.title} description={data?.handbook.seo?.metaDesc} /> */}
      <SeoPlugin post={data} title={data?.handbook.seo?.title} description={data?.handbook.seo?.metaDesc} />
      <ProgramHandbook data={data.all.edges}>
        <h2>{data.handbook.title}</h2>
        {imageObject ? (
          <GatsbyImage
            image={imageObject}
            alt={data.handbook.featuredImage?.node.altText}
            tw="mb-5"
            css={css`
              max-height: 400px;
              aspect-ratio: 16/9;
            `}
            imgStyle={{ borderRadius: `1.5rem` }}
          />
        ) : (
          <div />
        )}
        <Wysiwyg>{data.handbook.content}</Wysiwyg>
      </ProgramHandbook>
    </>
  )
}

export default Handbook

export const pageQuery = graphql`
  query ($id: String!) {
    handbook: wpParentHandbook(id: { eq: $id }) {
      id
      parentId
      uri
      slug
      title
      content
      featuredImage {
        node {
          altText
          gatsbyImage(placeholder: BLURRED, formats: [AUTO, WEBP], width: 1400)
        }
      }
      seo {
        title
        metaDesc
        readingTime
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
        breadcrumbs {
          text
          url
        }
      }
    }
    all: allWpParentHandbook(sort: { fields: menuOrder, order: ASC }) {
      edges {
        node {
          id
          parentId
          uri
          slug
          title
          content
          featuredImage {
            node {
              altText
              gatsbyImage(placeholder: BLURRED, formats: [AUTO, WEBP], width: 1400)
            }
          }
          seo {
            title
            metaDesc
            readingTime
            breadcrumbs {
              text
              url
            }
          }
        }
      }
    }
  }
`
